/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  background-color: #e5e5e5;
}

@media only screen and (max-width: 650px) {
  .hide-lt-650 {
    display: none !important;
  }
}

@media only screen and (max-width: 780px) {
  .hide-lt-780 {
    display: none !important;
  }
}
